<template>
    <div class="flex flex-item flex-column">
        <ts-table-operate :name="nameKey" :table-name="tableName" v-if="cols.length > 0" @clickFollowed="clickFollowed"
            :cols="cols" @handleFilter="handleFilter" show-export @leadingOut="exportInfo()" @handleSort="sort"
            @viewChange="viewChange" @searchShowLeft="searchShowLeft"></ts-table-operate>
        <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey"
            @handleRowDbClickChange="handleRowDbClickChange" full hide-detail @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
            </template>
            <template v-slot:slot_sale="{ props }">
                <el-button class="detail-button" type="primary" :disabled="props.row.def09 === '无'" round
                    @click="goDrugTotal(props.row)">查看</el-button>
            </template>

            <template v-slot:slot_star="{ props }">
                <div class="comment-star">
                    <div class="comment-star__item">
                        <i class="el-icon-loading" v-if="props.row.starLoading"></i>
                        <template v-else>
                            <i class="el-icon-star-on" v-if="props.row.thisUserAttention"
                                @click="removeStar(props.row)"></i>
                            <i class="el-icon-star-off" v-else @click="addStar(props.row)"></i>
                        </template>
                        {{ props.row.attentionNumber || '' }}
                    </div>
                </div>
            </template>
            <template v-slot:slot_comment="{ props }">
                <div class="comment-star">
                    <div class="comment-star__item">
                        <i class="el-icon-chat-line-round" @click="seeComment(props.row)"></i>
                        {{ props.row.appraiseNumber || '' }}
                    </div>
                </div>
            </template>
            <template v-slot:slot_note="{ props }">
                <div class="comment-star">
                    <div class="comment-star__item">
                        <i class="el-icon-edit-outline" @click="seeNote(props.row)"></i>
                        {{ props.row.noteBookNumber || '' }}
                    </div>
                </div>
            </template>
        </ts-table>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import searchMixin from './searchMixin'
export default {
    mixins: [myMixin, searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
        visitId: {},
    },
    data() {
        return {
            nameKey: 'corpProduct',
            tableName: 'aim_base_comp_drugs',
            pageApi: 'indexComp/aimBaseCompDrugs/queryAimBaseCompDrugsPage',
            cols: this.$help.generalCols([
                {
                    fieldName: "star",
                    name: "关注",
                    width: 50,
                    listClass: 'tc',
                    fixed: 'left',
                    hideOverflowTooltip: true,
                },
                {
                    fieldName: "comment",
                    name: "评论",
                    width: 50,
                    listClass: 'tc',
                    fixed: 'left',
                    hideOverflowTooltip: true,
                },
                {
                    fieldName: "note",
                    name: "笔记",
                    width: 50,
                    listClass: 'tc',
                    fixed: 'left',
                    hideOverflowTooltip: true,
                },
                this.$variable.indexColumn.drug(),
                {
                    fieldName: "def14",
                    name: "国产批文",
                    listOrderView: '是',
                    width: 80,
                    link: this.goChina,
                    listClass: 'tc',
                    explain: '左边数字：该企业药品批文数；右边数字：该药品批文总数'
                },
                {
                    fieldName: "def16",
                    name: "进口批文",
                    listOrderView: '是',
                    width: 80,
                    link: this.goForeign,
                    listClass: 'tc',
                    explain: '左边数字：该企业药品批文数；右边数字：该药品批文总数'
                },
                {
                    fieldName: "def18",
                    name: "原料药",
                    listOrderView: '是',
                    width: 80,
                    link: this.goMaterial,
                    listClass: 'tc',
                    explain: '左边数字：该企业药品原料药数；右边数字：该药品原料药总数',
                },
                {
                    fieldName: "def02",
                    name: "内企",
                    listOrderView: '是',
                    width: 60,
                    link: this.goChina,
                    listClass: 'tc',
                    // explain: '左边数字：该企业是否生产此药品（0表示不生产，1表示生产）；右边数字：该药品生产企业总数',
                    isIcon:true,
                    iconText:'国内生产企业数',
                },
                {
                    fieldName: "def08",
                    name: "外企",
                    listOrderView: '是',
                    width: 60,
                    link: this.goForeign,
                    listClass: 'tc',
                    // explain: '左边数字：该企业是否生产此药品（0表示不生产，1表示生产）；右边数字：该药品生产企业总数',
                    isIcon:true,
                    iconText:'国外生产企业数',
                },
                {
                    fieldName: "def04",
                    name: "医保",
                    listOrderView: '是',
                    listQueryView: "是",
                    listQueryModel: 1,
                    width: 60,
                    link: this.goHealth,
                    listClass: 'tc',
                },
                {
                    fieldName: "def05",
                    name: "基药",
                    listOrderView: '是',
                    listQueryView: "是",
                    listQueryModel: 1,
                    width: 60,
                    link: this.goBase,
                    listClass: 'tc',
                },
                {
                    fieldName: "def21",
                    name: "基药规格",
                    width: 120,
                },
                {
                    fieldName: "def06",
                    name: "中保",
                    listQueryView: "是",
                    listQueryModel: 1,
                    listOrderView: '是',
                    width: 60,
                    listClass: 'tc',
                    isIcon:true,
                    iconText:'中药保护品种',
                },
                {
                    fieldName: "nationalCentralized",
                    name: "国采",
                    listOrderView: '是',
                    width: 60,
                    listClass: 'tc',
                    link: this.goNation,
                    isIcon:true,
                    iconText:'国家集采批次',
                },
                {
                    fieldName: "def09",
                    name: "是否有销售",
                    listOrderView: '是',
                    listQueryView: "是",
                    listQueryModel: 1,
                    width: 80,
                    listClass: 'tc',
                },
                {
                    fieldName: "sale",
                    name: "历年销售情况",
                    width: 80,
                    listClass: 'tc',
                },
            ]),
            cndCluster: {
                op: 'or',
                cnds: [
                    { name: 'def01', op: 'ne', value: '0' },
                    { name: 'def07', op: 'ne', value: '0' },
                    { name: 'def02', op: 'ne', value: '0' },
                    { name: 'def08', op: 'ne', value: '0' },
                    { name: 'def03', op: 'ne', value: '0' },
                    { name: 'def04', op: 'isNull' },
                    { name: 'def05', op: 'ne', value: '否' },
                    { name: 'def21', op: 'isNull' },
                    { name: 'def06', op: 'ne', value: '否' },
                    { name: 'def09', op: 'ne', value: '无' },
                ]
            },

            followed: false,   //一键查询关注药品
            unModel: true,    //筛选加model
        }
    },
    methods: {
        //一键查询关注药品
        clickFollowed(flag) {
            if (this.loading) return false
            this.followed = flag
            this.init()
        },
        exportInfo(txt = '确定导出吗') {
            this.$help.exportInfo(txt, 'index/indexComp/exportQueryAimBaseCompDrugsPage', {
                params: this.baseQuery,
                cndCluster: this.cndCluster
            })
        },
        convertData(val) {
            if (val === 0 || val === '0') {
                return ''
            }
            return val
        },
        myAndAll(my, all) {
            if (all && all !== '0') {
                my = my || '0'
                return all ? `${my}/${all}` : ''
            } else {
                return ''
            }
        },
        listComputed(list) {
            return list.map(item => {

                item.def14 = this.myAndAll(item.def01, item.def14)
                item.def16 = this.myAndAll(item.def07, item.def16)
                item.def18 = this.myAndAll(item.def10, item.def18)

                item.def01 = this.convertData(item.def01)
                item.def07 = this.convertData(item.def07)
                item.def02 = this.convertData(item.def02)
                item.def08 = this.convertData(item.def08)
                item.def03 = this.convertData(item.def03)
                item.def10 = this.convertData(item.def10)
                return item
            })
        },

        // 添加收藏
        addStar(row) {
            if (this.$help.token.get()) {
                this.$set(row, 'starLoading', true)
                this.$api.post('sys/sysUserAttention/insertSysUserAttention', {
                    type: 2,
                    comCode: row.comCode,
                    comName: row.comName,
                    compCode: row.compCode,
                    compName: row.compName,
                })
                    .then(res => {
                        if (res.success) {
                            this.$api.get(`indexComp/aimBaseCompDrugs/queryAimBaseCompDrugsPageShow?id=${row.id}`)
                                .then(res => {
                                    row.attentionNumber = row.attentionNumber + 1
                                    this.$set(row, 'thisUserAttention', res.data.thisUserAttention)
                                })
                                .finally(() => {
                                    this.$set(row, 'starLoading', false)
                                })
                        }
                    })
                    .catch(() => {
                        this.$set(row, 'starLoading', false)
                    })
            } else {
                this.$help.goLogin()
            }
        },
        // 取消收藏
        removeStar(row) {
            if (this.$help.token.get()) {
                this.$set(row, 'starLoading', true)
                this.$api.post('sys/sysUserAttention/deleteByIdList', [row.thisUserAttention.id])
                    .then(res => {
                        if (res.success) {
                            row.attentionNumber = row.attentionNumber - 1
                            row.thisUserAttention = null
                        }
                    })
                    .finally(() => {
                        this.$set(row, 'starLoading', false)
                    })
            } else {
                this.$help.goLogin()
            }
        },

        // 查看评价列表
        seeComment(row) {
            this.$store.commit('showComment', {
                type: '2',
                comCode: row.comCode,
                comName: row.comName,
                compCode: row.compCode,
                compName: row.compName,
                cb: this.getList,
            })
        },
        seeNote(row) {
            this.$store.commit('showNote', {
                type: '2',
                comCode: row.comCode,
                comName: row.comName,
                compCode: row.compCode,
                compName: row.compName,
                cb: this.getList,
            })
        },
    },
}
</script>