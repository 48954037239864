<style lang="scss">
.dialog-news {

    .el-dialog {
        margin-bottom: 0;
    }

    .el-dialog__body {
        padding: 10px 0;
    }

    .panel {
        padding: 0 40px;
    }

    .title {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        padding: 30px 0;
        text-align: center;
    }

    .assist {
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        color: #838282;
        line-height: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E9E9E9;

        span {
            display: inline-block;
            padding: 0 36px;
        }
    }
}
</style>

<template>
    <div class="flex flex-item flex-column">
        <ts-table-operate :name="nameKey" :table-name="tableName" v-if="cols.length > 0" :cols="cols" @searchShowLeft="searchShowLeft"
            @handleFilter="handleFilter" @handleSort="sort" @viewChange="viewChange"></ts-table-operate>
        <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey"
            @handleRowDbClickChange="handleRowDbClickChange" full hide-detail @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols"  :table-name="tableName" :cols="cols" 
                @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
            </template>
            <template v-slot:slot_operate="{ props }">
                <el-button class="detail-button" type="primary" round @click="seeDetail(props.row)">查看</el-button>
            </template>
        </ts-table>


        <ts-dialog class="dialog-news" :show.sync="dialog.show" :title="dialog.form.title" hide-footer top="0"
            width="860px">
            <!--<div v-html="dialog.form.content_text" style="overflow: auto;"></div>-->
            <div class="panel" :style="{ height: height + 'px', overflow: 'auto' }">
                <p class="title">{{ dialog.form.title }}</p>
                <p class="assist">
                    <span>作者：{{ dialog.form.author }}</span>
                    <span>时间：{{ dialog.form.publishDate }}</span>
                </p>
                <div v-html="dialog.form.contentText"></div>
            </div>
        </ts-dialog>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import searchMixin from './searchMixin'
export default {
    mixins: [myMixin, searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
        visitId: {},
    },
    data() {
        return {
            height: document.documentElement.clientHeight - 77,
            nameKey: 'corpNews',
            tableName: 'base_comp_news',
            pageApi: 'indexComp/baseCompNews/compNews',
            cols: this.$help.generalCols([
                { fieldName: 'title', name: '标题', width: 150, listQueryView: '是', listOrderView: '是', },
                { fieldName: 'author', name: '作者', width: 100, listQueryView: '是', listOrderView: '是', },
                { fieldName: 'publishDate', name: '时间', width: 100, listOrderView: '是', },
                { fieldName: 'articleKeyword', name: '关键字', width: 200, listOrderView: '是', },
                { fieldName: 'operate', name: '操作', width: 50, listClass: 'tc' }
            ]),

            dialog: {
                show: false,
                form: {},
            }
        }
    },
    methods: {
        seeDetail(row) {
            this.dialog.show = true
            this.dialog.form = row
        },
    }
}
</script>