<template>
    <div ref="panel" class="with-sale flex full flex-column flex-item">
        <template v-if="loaded">
            <corp-kind-sale-info-year ref="year" class="with-sale-item" :class="{ active: type === 1 }"
                :realHeight="realHeight" :base-query="baseQuery" :visitId="visitId"></corp-kind-sale-info-year>
            <corp-kind-sale-info-quarter ref="quarter" class="with-sale-item" :class="{ active: type === 2 }"
                :realHeight="realHeight" :base-query="baseQuery"></corp-kind-sale-info-quarter>
        </template>
    </div>
</template>

<script>
import CorpKindSaleInfoYear from './CorpKindSaleInfoYear'
import CorpKindSaleInfoQuarter from './CorpKindSaleInfoQuarter'

export default {
    components: { CorpKindSaleInfoYear, CorpKindSaleInfoQuarter },
    props: {
        baseQuery: {
            default: () => ({})
        },
        visitId: {},
    },
    data() {
        return {
            // 1-年，2-季度
            type: 1,

            realHeight: 100,
            loaded: false,
        }
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 50
        this.loaded = true
    },
}
</script>