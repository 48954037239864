<template>
    <div class="corp-info flex-item" v-loading="loading">
        <el-row :gutter="40" v-for="(parent, i) in list" :key="'corpInfo' + i">
            <el-col :span="item.span || parent.span" v-for="item in parent.children" :key="item.key">
                <div class="corp-info-item">
                    <div class="title">
                        {{ item.value }}：
                        <div v-if="item.key === 'compName'" class="comment-star" style="font-size: 14px">
                            <div class="comment-star__item">
                                <i class="el-icon-loading" v-if="starLoading"></i>
                                <template v-else>
                                    <i class="el-icon-star-on" v-if="corp.thisUserAttention" @click="removeStar"></i>
                                    <i class="el-icon-star-off" v-else @click="addStar"></i>
                                </template>
                                {{ corp.attentionNumber || '' }}
                            </div>
                            <div class="comment-star__item">
                                <i class="el-icon-chat-line-round" @click="seeComment"></i>
                                {{ corp.appraiseNumber || '' }}
                            </div>
                            <div class="comment-star__item">
                                <i class="el-icon-edit-outline" @click="seeNote"></i>
                                {{ corp.noteBookNumber || '' }}
                            </div>
                        </div>
                    </div>
                    <p class="content">{{ corp[item.key] || '&nbsp;' }}</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: ['code', 'visitId'],
    data() {
        return {
            loading: false,
            corp: {},
            list: [
                {
                    span: 6,
                    children: [
                        { key: 'compName', value: '企业名称' },
                        { key: 'groupName', value: '集团名称' },
                        { key: 'socialCredit', value: '组织代码' },
                        { key: 'legalPerson', value: '法定代表人' },
                    ]
                },
                {
                    span: 6,
                    children: [
                        { key: 'personEnterprise', value: '企业负责人' },
                        { key: 'openDate', value: '发证日期' },
                        { key: 'dateExpiry', value: '有效期至' },
                        { key: 'signer', value: '签发人' },
                    ]
                },
                {
                    span: 6,
                    children: [
                        { key: 'mobileSupervisor', value: '监督举报电话' },
                        { key: 'province', value: '省份' },
                        { key: 'bondCode', value: '证券代码' },
                        { key: 'compStatus', value: '企业状态' },
                    ]
                },
                {
                    span: 6,
                    children: [
                        { key: 'licenceIssuingAuthority', value: '发证机关' },
                        { key: 'routineSupervisionOrganization', value: '日常监管机构', span: 18 },
                    ]
                },
                {
                    span: 24,
                    children: [
                        { key: 'registeredAddress', value: '注册地址' }
                    ]
                },
                {
                    span: 24,
                    children: [
                        { key: 'productionAddress', value: '生产地址' }
                    ]
                },
                {
                    span: 24,
                    children: [
                        { key: 'productionRange', value: '生产范围' },
                    ]
                },
            ],
            starLoading: false,
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.loading = true
            this.$api.post('indexComp/aimBaseComp/queryAimBaseCompPage', { params: { compCode: this.code } }, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
                .then(res => {
                    this.corp = res.data.records[0] || {}
                })
                .finally(() => {
                    this.loading = false
                })
        },
        // 添加收藏
        addStar() {
            if (this.$help.token.get()) {
                this.starLoading = true
                this.$api.post('sys/sysUserAttention/insertSysUserAttention', {
                    type: 1,
                    compCode: this.corp.compCode,
                    compName: this.corp.compName,
                })
                    .then(() => {
                        this.init()
                    })
                    .finally(() => {
                        this.starLoading = false
                    })
            } else {
                this.$help.goLogin()
            }
        },
        // 取消收藏
        removeStar() {
            if (this.$help.token.get()) {
                this.starLoading = true
                this.$api.post('sys/sysUserAttention/deleteByIdList', [this.corp.thisUserAttention.id])
                    .then(res => {
                        if (res.success) {
                            this.corp.attentionNumber = this.corp.attentionNumber - 1
                            this.corp.thisUserAttention = null
                        }
                    })
                    .finally(() => {
                        this.starLoading = false
                    })
            } else {
                this.$help.goLogin()
            }
        },

        // 查看评价列表
        seeComment() {
            this.$store.commit('showComment', {
                type: '1',
                compCode: this.corp.compCode,
                compName: this.corp.compName,
                cb: this.init,
            })
        },
        seeNote() {
            this.$store.commit('showNote', {
                type: '1',
                compCode: this.corp.compCode,
                compName: this.corp.compName,
                cb: this.init,
            })
        },
    }
}
</script>