<template>
    <div class="common-table search-result">
        <div class="common-table__title" style="display:flex;">
            <div class="common-table__title-list" v-if="corpList.length > 0">
                <el-dropdown size="normal" @command="checkCorp">
                    <span class="el-dropdown-link">
                        {{ corp.compName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu class="search-popup" slot="dropdown">
                        <el-dropdown-item v-for="item in corpList" :key="item.compCode" :command="item"
                            :disabled="item.compCode === corp.compCode">{{ item.compName }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

            </div>
            <div class="flex flex-item" v-else>
                <div class="common-table__title-list" style="flex: unset">{{ isEmpty ? '快速检索' : corp.compName }}</div>
                <div v-show="$help.token.get()" style="margin-left: 10px;">
                    <el-button type="primary" round @click="goMyStar">我的关注和评论</el-button>
                </div>
            </div>
            <ts-search :param="express"></ts-search>
        </div>

        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="base"><span slot="label">{{ pageMap.base }}</span></el-tab-pane>
                <el-tab-pane name="product"><span slot="label">{{ pageMap.product }}</span></el-tab-pane>
                <el-tab-pane name="dev"><span slot="label">{{ pageMap.dev }}</span></el-tab-pane>
                <el-tab-pane name="one"><span slot="label">{{ pageMap.one }}</span></el-tab-pane>
                <el-tab-pane name="bid"><span slot="label">{{ pageMap.bid }}</span></el-tab-pane>
                <!--<el-tab-pane name="sale"><span slot="label">产品销售信息</span></el-tab-pane>-->
                <el-tab-pane name="kind"><span slot="label">{{ pageMap.kind }}</span></el-tab-pane>
                <el-tab-pane name="news"><span slot="label">{{ pageMap.news }}</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{ show: tab === 'base' }">
                <corp-base-info v-if="finished.base" :code="corp.compCode" :visit-id="visitId"></corp-base-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'product' }">
                <corp-product-info v-if="finished.product" :base-query="baseQuery"
                    :visit-id="visitId"></corp-product-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'dev' }">
                <corp-dev-info v-if="finished.dev" :base-query="baseQuery" :visit-id="visitId"></corp-dev-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'one' }">
                <corp-uniformity-info v-if="finished.one" :base-query="baseQuery"
                    :visit-id="visitId"></corp-uniformity-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'bid' }">
                <corp-bid-info v-if="finished.bid" :base-query="baseQuery" :visit-id="visitId"></corp-bid-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'kind' }">
                <corp-kind-sale-info v-if="finished.kind" :base-query="baseQuery"
                    :visit-id="visitId"></corp-kind-sale-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'news' }">
                <corp-news-info v-if="finished.news" :base-query="baseQuery" :visit-id="visitId"></corp-news-info>
            </div>
        </div>
    </div>
</template>

<script>
import CorpBaseInfo from './CorpBaseInfo'
import CorpProductInfo from './CorpProductInfo'
import CorpDevInfo from './CorpDevInfo'
import CorpUniformityInfo from './CorpUniformityInfo'
import CorpBidInfo from './CorpBidInfo'
import CorpKindSaleInfo from './CorpKindSaleInfo'
import CorpNewsInfo from './CorpNewsInfo'
export default {
    components: {
        CorpBaseInfo, CorpProductInfo, CorpDevInfo, CorpUniformityInfo, CorpBidInfo, CorpKindSaleInfo, CorpNewsInfo
    },
    data() {
        return {
            visitId: 0,
            pageId: '',
            pageName: '',
            pageTitleSelf: true,

            pageMap: {
                base: '基本信息',
                product: '产品信息',
                dev: '产品研发信息',
                one: '一致性评价信息',
                bid: '招投标信息',
                sale: '产品销售信息',
                kind: '企业品种销售信息',
                news: '企业新闻',
            },
            tab: 'base',
            loaded: false,
            express: {
                txt: '',
                type: '',
            },
            baseQuery: {},

            finished: {
                base: false,
                product: false,
                dev: false,
                one: false,
                bid: false,
                sale: false,
                kind: false,
                news: false,
            },

            corpList: [],
            corp: {},
            isEmpty: false,
        }
    },
    created() {
        this.init();
        this.$help.setTitle('企业搜索-' + this.$route.params.txt)
    },
    watch: {
        '$route': function () {
            this.init();
        },
        tab: {
            handler(val) {
                this.finished[val] = true

                this.visitId = new Date().getTime()
                this.pageId = val
                this.pageName = '企业搜索-' + this.pageMap[val]

                this.$help.socket.send(this)
            },
            immediate: true
        },
    },
    methods: {
        init() {
            const txt = this.$route.params.txt
            this.corpList = []
            this.corp = {}
            this.$api.get('setting/aimBaseComp/queryAimBaseCompListByKeyword', { keyword: txt })
                .then(res => {
                    if (res.data && res.data.length > 1) {
                        this.corpList = res.data
                        this.checkCorp(res.data[0])
                    } else if (res.data.length === 0) {
                        this.isEmpty = true
                    } else {
                        this.checkCorp(res.data[0])
                    }

                })

            this.express.txt = txt
            this.express.type = this.$variable.indexType.CORP
        },
        checkCorp(item) {
            if (item.compCode !== this.corp.compCode) {
                this.corp = item
                this.tab = 'base'
                this.loaded = false
                this.baseQuery = {
                    // compName: item.compName,
                    compCode: item.compCode
                    //keyword: item.compName,
                }
                this.$nextTick(() => {
                    this.loaded = true
                })
            }
        },
        goMyStar() {
            const { href } = this.$router.resolve({
                path: `/star`,
            })
            window.open(href, "_blank")
        },
    },
}
</script>