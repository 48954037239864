<template>
    <div class="flex flex-item flex-column">
        <ts-table-operate :name="nameKey" :table-name="tableName" v-if="cols.length > 0"
            @searchShowLeft="searchShowLeft" :cols="cols" @handleFilter="handleFilter" show-export
            @leadingOut="exportInfo()" @handleSort="sort" @viewChange="viewChange"></ts-table-operate>
        <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey"
            @handleRowDbClickChange="handleRowDbClickChange" full @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :detailApiId="detailApiId">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
            </template>
        </ts-table>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import searchMixin from './searchMixin'
export default {
    mixins: [myMixin, searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
        visitId: {},
    },
    data() {
        return {
            config: {
                key: 'flow_development_review_progress'
            },
            nameKey: 'corpUniformity',
            detailApiId: {
                api: 'indexComp/flowDevelopmentReviewProgress/developmentReviewProgressShow',
                id: 'rdRegistrationAcceptanceId'
            },
            tableName: 'flow_development_review_progress',
            pageApi: 'indexComp/flowDevelopmentReviewProgress/flowDevelopmentReviewProgress',
            cols: []
        }
    },
    methods: {
        exportInfo(txt = '确定导出吗') {
            this.$help.exportInfo(txt, 'indexComp/flowDevelopmentReviewProgress/exportFlowDevelopmentReviewProgress', { params: this.baseQuery })
        },
    }
}
</script>