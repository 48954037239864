<style lang="scss">
.fix-table {

    .el-table__fixed,
    .el-table__fixed-right {
        height: auto !important;
        bottom: 10px !important;
    }
}
</style>
<template>
    <div class="scroll-div" style="position: unset;">
        <ts-table-operate style="margin: 0 20px" :name="nameKey" :table-name="tableName" v-if="cols.length > 0"
            :cols="cols" @handleFilter="handleFilter" show-export @leadingOut="exportInfo()" @handleSort="sort"
            @viewChange="viewChange" @searchShowLeft="searchShowLeft">
            <div slot="left" style="margin-right: 10px;">
                （销售额单位：{{ $variable.unit }}）
                <el-radio-group v-model="$parent.type" size="small">
                    <el-radio-button :label="1">年</el-radio-button>
                    <el-radio-button :label="2">季</el-radio-button>
                </el-radio-group>
            </div>
        </ts-table-operate>
        <ts-table :style="{ height: realHeight + 'px' }" ref="table" zero-to-empty :cols="cols" :data="list"
            :page-param="pageParam" :loading="loading" @handleSort="sort" :error="pageParam.error" :error-code="error"
            :name="nameKey" @handleRowDbClickChange="handleRowDbClickChange" hide-detail is-single full
            @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :summaryMethod="summaryFun">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
            </template>
            <template v-slot:slot_sale="{ props }">
                <el-button class="detail-button" type="primary" round @click="goDrugTotal(props.row)">查看</el-button>
            </template>
        </ts-table>

        <div class="total-title__line"></div>

        <div class="total-title">
            产品销售数据图-全部
            <div class="total-title__right"></div>
        </div>
        <ts-error v-if="canvas.message === 405"></ts-error>
        <div v-else :id="nameKey1" class="canvas small"></div>

        <div class="total-title__line"></div>
        <div class="total-title">
            产品销售数据图
            <div class="total-title__right">筛选：
                <el-select v-model="canvas.model" remote :remote-method="remoteMethod" value-key="id" filterable
                    style="width: 300px" @change="showProduct" :loading="canvas.loading">
                    <el-option v-for="item in canvas.list" :key="item.id" :value="item"
                        :label="item.comName + ' ' + item.unifySpec">
                        {{ item.comName }}<span style="font-size: 12px;color: #999;padding-left: 15px;">{{
            item.unifySpec
        }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <ts-error v-if="canvas.message === 405"></ts-error>
        <div :id="nameKey" class="canvas small"></div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import searchMixin from './searchMixin'
export default {
    mixins: [myMixin, searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
        realHeight: {},
        txt: {},
        visitId: {},
    },
    data() {
        return {
            nameKey: 'corpKindSaleYear',
            nameKey1: 'corpKindSaleYear1',
            tableName: 'sale_year',
            pageApi: 'indexComp/saleYear/drugSaleInfoYear',
            sumData: [],
            cols: this.$help.generalCols([
                this.$variable.indexColumn.drug({ listStipulate: 1 }),
                {
                    fieldName: 'unifyDose',
                    name: '剂型',
                    listQueryView: '是',
                    listOrderView: '是',
                    width: 100,
                    listClass: 'tc'
                },
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    listOrderView: '是',
                    listQueryView: '是',
                    width: 100,
                    listQueryModel: 1,
                    listStipulate: 1
                },
                ...this.$variable.getYearCols({
                    listOrderView: '是'
                }),
                // ...this.$variable.getYearRateCols(),
                ...this.$variable.years.map(item => ({
                    fieldName: 'rate' + item.value,
                    name: item.value + '年增长比%',
                    listOrderView: '是',
                    listClass: 'tc',
                    width: 60,
                })),
            ]),
            active: '',

            canvas: {
                show: false,
                message: 200,
                loading: false,
                model: {},
                list: [],
            },
        }
    },
    created() {
        this.remoteMethod()

        // 加载全部销售数据
        this.$api.get('indexComp/saleYear/sumYearAmountAndRateByCompCode', this.baseQuery)
            .then(res => {
                if (res.code === 'A0004') {
                    this.message = 405
                } else {
                    const list = res.data.map(item => {
                        // item.year = this.$variable.yearMap[item.year]
                        item.rate = this.$help.parseFloat100(item.rate)
                        return item
                    })
                    this.$help.renderBar(this.nameKey1, list, 'year')
                }
            })
    },
    methods: {
        remoteMethod(keyword) {
            this.canvas.loading = true
            this.$api.post(this.pageApi, {
                params: {
                    compCode: this.baseQuery.compCode,
                    comName: keyword ? `%${keyword}%` : undefined,
                },
                page: {
                    pageNo: 1,
                    pageSize: 20,
                },
            })
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.canvas.list = res.data.records.map(item => {
                            item.id = item.comCode + item.compCode + item.unifySpec
                            return item
                        })
                        if (!this.canvas.model.id && this.canvas.list.length !== 0) {
                            this.canvas.model = this.canvas.list[0]
                            this.showProduct()
                        }
                    }
                })
                .finally(() => {
                    this.canvas.loading = false
                })
        },
        listComputed(list) {
            list.map(item => {
                this.$variable.years.forEach(year => {
                    const field = `rate${year.value}`
                    item[field] = this.$help.parseFloat100(item[field])
                })
                return item
            })
            return list
        },
        sumDataComputed(list) {
            this.$variable.years.forEach((year) => {
                const field = `rate${year.value}`;
                list[field] = list[field] ? this.$help.parseFloat100(list[field]) : '';
            });
            return list;
        },
        exportInfo(txt = '确定导出吗') {
            this.$help.exportInfo(txt, 'indexComp/saleYear/exportDrugSaleInfoYear', { params: this.baseQuery })
        },
        showProduct() {
            let arr = []
            // console.log(this.canvas.model)
            this.$variable.years.forEach(item => {
                const obj = {
                    comName: this.canvas.model.comName,
                    compName: this.canvas.model.compName,
                    amount: this.canvas.model['amount' + item.value],
                    rate: this.$help.parseFloat100(this.canvas.model['rate' + item.value]),
                    year: item.value,
                }
                arr.push(obj)
            })
            this.$help.renderBar(this.nameKey, arr)
        },
    }
}
</script>